import './Footer.scss';

const Footer = () => {
  return (
    <footer className="footer">
      <p className="footer__paragraph">
        &#169; InStock Inc. All Rights Reserved.
      </p>
    </footer>
  );
}

export default Footer;