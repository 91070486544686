import './WarehouseDetailsList.scss'
import deleteIcon from '../../assets/images/icons/delete_outline-24px.svg';
import editIcon from '../../assets/images/icons/edit-24px.svg';
import chevronIcon from '../../assets/images/icons/chevron_right-24px.svg';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { InvModal } from '../Modal/InventoryModal';

const WarehouseDetailsList = ({ product, i }) => {

  const [showModal, setShowModal] = useState(false)
  const openModal = () => {
    setShowModal(prev => !prev)
  };

  if (!product) {
    return <h1>Loading...</h1>
  };

  return (
    <div className={`details-list${i === 0 ? ' details-list--border' : ''}`}>
      <div className='details-list__wrapper'>
        <div className='details-list__container details-list__container--left'>
          <span className='details-list__label'>INVENTORY ITEM</span>
          <Link className='details-list__link details-list__link--width' to={`/inventory/${product.id}/`}>
            <span className='details-list__product details-list__product--color'>{product.itemName}</span>
            <img className='details-list__product-chevron' src={chevronIcon} alt="chevron right" />
          </Link>
          <span className='details-list__label'>CATEGORY</span>
          <span className='details-list__product details-list__product--category-width'>{product.category}</span>
        </div>
        <div className='details-list__container details-list__container--right'>
          <span className='details-list__label'>STATUS</span>
          <div className='details-list__stock-container'>
            {/* Adds the appropriate class for in stock or out of stock styling */}
            <span className={`details-list__product${product.quantity === 0 ? ' details-list__product--out' : ' details-list__product--in'}`}>{product.status.toUpperCase()}</span>
          </div>
          <span className='details-list__label'>QTY</span>
          <span className='details-list__product details-list__product--quantity-width'>{product.quantity}</span>
        </div>
      </div>
      <div className='details-list__button-container'>
      <InvModal showModal={showModal} setShowModal={setShowModal} productID={product.id} productName={product.itemName} />
          <img style={{cursor: 'pointer'}} className='details-list__icon details-list__icon--margin' onClick={openModal} src={deleteIcon} alt="delete" />
        <Link className='details-list__link' to={`/inventory/${product.id}/edit`}>
          <img className='details-list__icon' src={editIcon} alt="edit" />
        </Link>
      </div>
    </div>
  )
}

export default WarehouseDetailsList