import './InventoryItem.scss';
import backIcon from '../../assets/images/icons/arrow_back-24px.svg';
import editIcon from '../../assets/images/icons/edit-white-24px.svg';
import { useState, useEffect } from 'react'
import { useParams, Link, useNavigate } from 'react-router-dom'
import axios from 'axios';

const InventoryItem = () => {

	const API_URL = process.env.REACT_APP_API_URL;
	let { id } = useParams()
	const url = `${API_URL}/inventory/${id}`
	let [currentInventory, setCurrentInventory] = useState(null)
	let navigate = useNavigate()

	useEffect(() => {
		axios.get(url).then(res => {
			setCurrentInventory(res.data[0]);
		})
	}, [url])

	if (currentInventory === null) {
		return <h1>Loading...</h1>
	}

	return (
		<div className='item-details'>
			<div className='item-details__top'>
				<div className='item-details__title-wrapper'>
					<Link className='item-details__link' to='/inventory'>
						<img className='item-details__icon--back' src={backIcon} alt="back" />
					</Link>
					<h1 className='item-details__title'>{currentInventory.itemName}</h1>
				</div>
				<button style={{ cursor: 'pointer' }} onClick={() => navigate(`/inventory/${currentInventory.id}/edit`)} className='item-details__button' to='/'>
					<img src={editIcon} alt="edit" />
					<span className='item-details__icon-text'>Edit</span>
				</button>
			</div>
			<div className='item-details__bottom'>
				<div className='item-details__card--one'>
					<div className='item-details__item-description'>
						<h2 className='item-details__label' >ITEM DESCRIPTION:</h2>
						<p className='item-details__text' >{currentInventory.description}</p>
					</div>
					<div className='item-details__item-category'>
						<h2 className='item-details__label' >CATEGORY:</h2>
						<p className='item-details__text' >{currentInventory.category}</p>
					</div>
				</div>
				<div className='item-details__card--right'>
					<div className='item-details__card--two'>
						<div className='item-deatils__status'>
							<h2 className='item-details__label' >STATUS:</h2>
							<div className={`${currentInventory.quantity !== 0 ? `item-details__status--in` : `item-details__status--out`}`} >{currentInventory.status}</div>
						</div>
						<div className='item-details__quantity'>
							<h2 className='item-details__label' >QUANTITY:</h2>
							<p className='item-details__text' >{currentInventory.quantity}</p>
						</div>
					</div>
					<div className='item-details__warehouse'>
						<h2 className='item-details__label' >WAREHOUSE:</h2>
						<p className='item-details__text' >{currentInventory.warehouseName}</p>
					</div>
				</div>
			</div>
		</div>
	)
}

export default InventoryItem