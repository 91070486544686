import './ErrorPage.scss';
import { useNavigate } from 'react-router-dom';

const ErrorPage = () => {
  //use navigates redirects to home page
  const navigate = useNavigate();
  const returnHome = () => {
    navigate('/warehouse');
  };
  return (
    <div className="errorpage">
      <h1 className="errorpage__heading">
        You've landed in the middle of nowhere.
      </h1>
      <p className="errorpage__description">
        Head back to the homepage here ⬇️
      </p>
      <button className="errorpage__button" onClick={returnHome}>
        <span className='errorpage__btnlabel'>Homepage</span>
      </button>
    </div>
  );
};

export default ErrorPage;
