import "./AddNewWarehouse.scss";
import leftArrow from "../../assets/images/icons/arrow_back-24px.svg";
import errorIcon from "../../assets/images/icons/error-24px.svg";
import { useNavigate, NavLink } from "react-router-dom";
import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

//formatting the phone number while the user types

function formatPhoneNumber(value) {

  // if input value is falsy eg if the user deletes the input, then just return
  if (!value) return value;

  const phoneNumber = value.replace(/[^\d]/g, "");
  const phoneNumberLength = phoneNumber.length;

  if (phoneNumberLength < 2) return `+${phoneNumber.slice(0)} `;
  if (phoneNumberLength < 8) {
    return `+${phoneNumber.slice(0, 1)} (${phoneNumber.slice(
      1,
      4
    )}) ${phoneNumber.slice(4)}`;
  }
  return `+${phoneNumber.slice(0, 1)} (${phoneNumber.slice(
    1,
    4
  )}) ${phoneNumber.slice(4, 7)}-${phoneNumber.slice(7, 11)}`;
}


function AddWarehouse() {
  const navigate = useNavigate();

  //states for various inputs
  const [warehouseName, setWarehouseName] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [contactName, setContactName] = useState("");
  const [position, setPosition] = useState("");
  const [number, setNumber] = useState("");
  const [email, setEmail] = useState("");

  //validating errors of all forms
  const [warehouseNameError, setWarehouseNameError] = useState(false);
  const [addressError, setAddressError] = useState(false);
  const [cityError, setCityError] = useState(false);
  const [countryError, setCountryError] = useState(false);
  const [contactNameError, setContactNameError] = useState(false);
  const [positionError, setPositionError] = useState(false);
  const [numberError, setNumberError] = useState(false);
  const [emailError, setEmailError] = useState(false);

  //validating the email
  const [error, setError] = useState(null);
  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  // Checking if a single fields is filled
  const handleChangeName = (event) => {
    setWarehouseName(event.target.value);
    if (event.target.value < 1) {
      setWarehouseNameError(true);
    } else setWarehouseNameError(false);
  };
  const handleChangeAddress = (event) => {
    setAddress(event.target.value);
    if (event.target.value < 1) {
      setAddressError(true);
    } else setAddressError(false);
  };
  const handleChangeCity = (event) => {
    setCity(event.target.value);
    if (event.target.value < 1) {
      setCityError(true);
    } else setCityError(false);
  };
  const handleChangeCountry = (event) => {
    setCountry(event.target.value);
    if (event.target.value < 1) {
      setCountryError(true);
    } else setCountryError(false);
  };
  const handleChangeContactName = (event) => {
    setContactName(event.target.value);
    if (event.target.value < 1) {
      setContactNameError(true);
    } else setContactNameError(false);
  };
  const handleChangePosition = (event) => {
    setPosition(event.target.value);
    if (event.target.value < 1) {
      setPositionError(true);
    } else setPositionError(false);
  };

  //formatting the phone number as the user types
  const handleChangeNumber = (event) => {
    const formattedPhoneNumber = formatPhoneNumber(event.target.value);
    setNumber(formattedPhoneNumber);
    if (event.target.value < 1) {
      setNumberError(true);
    } else setNumberError(false);
  };
  const handleChangeEmail = (event) => {
    if (!isValidEmail(event.target.value)) {
      setError('Email is invalid');
    } else {
      setError(null)
    }
    if (event.target.value < 1) {
      setEmailError(true);
    } else setEmailError(false);
    setEmail(event.target.value)
  };

  const isWarehouseNameValid = () => {
    if (warehouseName === '') {
      return false;
    }
    return true;
  };
  const isAddressValid = () => {
    if (address === '') {
      return false;
    }
    return true;
  };
  const isCityValid = () => {
    if (city === '') {
      return false;
    }
    return true;
  };
  const isCountryValid = () => {
    if (country === '') {
      return false;
    }
    return true;
  };
  const isContactNameValid = () => {
    if (contactName === '') {
      return false;
    }
    return true;
  };
  const isPositionValid = () => {
    if (position === '') {
      return false;
    }
    return true;
  };
  const isNumberValid = () => {
    if (number === '') {
      return false;
    }
    return true;
  };
  const isEmailValid = () => {
    if (email === '') {
      return false;
    }
    return true;
  };


  const isFormValid = () => {
    //Check if the name field is valid
    if (
      !warehouseName ||
      !address ||
      !city ||
      !country ||
      !contactName ||
      !position ||
      !number ||
      !email
    ) {
      return false;
    }

    return true;
  };

  //formatting data submission
  const newWarehouse = {
    id: uuidv4(),
    name: warehouseName,
    address: address,
    city: city,
    country: country,
    contact: {
      name: contactName,
      position: position,
      phone: number,
      email: email,
    },
  };

  //posting data to backend
  const handleSubmit = (event) => {
    event.preventDefault();
    if (isFormValid()) {
      axios
        .post(`${API_URL}/warehouse`, newWarehouse)
        .then((response) => { alert("Sucessfully Added!") })
        .then(navigate("/warehouse"))
        .catch((error) => {
          alert("ERROR");
        });
    } else {
      if (!isWarehouseNameValid()) {
        setWarehouseNameError(true);
      }
      if (!isAddressValid()) {
        setAddressError(true);
      }
      if (!isCityValid()) {
        setCityError(true);
      }
      if (!isCountryValid()) {
        setCountryError(true);
      }
      if (!isContactNameValid()) {
        setContactNameError(true);
      }
      if (!isPositionValid()) {
        setPositionError(true);
      }
      if (!isNumberValid()) {
        setNumberError(true);
      }
      if (!isEmailValid()) {
        setEmailError(true);
      }
      alert("Please fill up all inputs");
    }
  };

  return (
    <>
      {/* form begins here */}
      <form className="addWarehouse" onSubmit={handleSubmit}>
        <div className="addWarehouse__headingcontainer">
          <NavLink to="/warehouse">
            <img src={leftArrow} alt="left arrow" />
          </NavLink>
          <h1 className="addWarehouse__header">Add New Warehouse</h1>
        </div>
        <div className="addWarehouse__form">
          <div className="addWarehouse__detail">
            <h2 className="addWarehouse__subheading">Warehouse Details</h2>
            {/* form name input */}
            <div className="addWarehouse__name">
              <h3 className="addWarehouse__label"> Warehouse Name</h3>
              <input
                className={`addWarehouse__name--input${warehouseNameError === false ? "" : " input--error"
                  }`}
                placeholder="Warehouse Name"
                onChange={handleChangeName}
              />
              <p
                className={`error${warehouseNameError === false ? "" : " error--active"
                  }`}
              >
                <img
                  src={errorIcon}
                  alt="error icon"
                  className="error__icon"
                ></img>
                This field is required
              </p>
            </div>
            {/* form address input */}
            <div className="addWarehouse__address">
              <h3 className="addWarehouse__label"> Street Address</h3>
              <input
                className={`addWarehouse__address--input${addressError === false ? "" : " input--error"
                  }`}
                placeholder="Street address"
                onChange={handleChangeAddress}
              />
              <p
                className={`error${addressError === false ? "" : " error--active"
                  }`}
              >
                <img
                  src={errorIcon}
                  alt="error icon"
                  className="error__icon"
                ></img>
                This field is required
              </p>
            </div>
            {/* form city input */}
            <div className="addWarehouse__city">
              <h3 className="addWarehouse__label">City</h3>
              <input
                placeholder="City"
                onChange={handleChangeCity}
                className={`addWarehouse__city--input${cityError === false ? "" : " input--error"
                  }`}
              />
              <p
                className={`error${cityError === false ? "" : " error--active"
                  }`}
              >
                <img
                  src={errorIcon}
                  alt="error icon"
                  className="error__icon"
                ></img>
                This field is required
              </p>
            </div>
            {/* form country input */}
            <div className="addWarehouse__country">
              <h3 className="addWarehouse__label">Country</h3>
              <input
                placeholder="Country"
                onChange={handleChangeCountry}
                className={`addWarehouse__country--input${countryError === false ? "" : " input--error"
                  }`}
              />
              <p
                className={`error${countryError === false ? "" : " error--active"
                  }`}
              >
                <img
                  src={errorIcon}
                  alt="error icon"
                  className="error__icon"
                ></img>
                This field is required
              </p>
            </div>
          </div>
          {/* form contact input */}
          <div className="addWarehouse__contact">
            <h2 className="addWarehouse__subheading">Contact Details</h2>
            <div className="addWarehouse__contactName">
              <h3 className="addWarehouse__label">Contact Name</h3>
              <input
                placeholder="Name"
                onChange={handleChangeContactName}
                className={`addWarehouse__contactName--input${contactNameError === false ? "" : " input--error"
                  }`}
              />
              <p
                className={`error${contactNameError === false ? "" : " error--active"
                  }`}
              >
                <img
                  src={errorIcon}
                  alt="error icon"
                  className="error__icon"
                ></img>
                This field is required
              </p>
            </div>
            {/* form position input */}
            <div className="addWarehouse__position">
              <h3 className="addWarehouse__label"> Position</h3>
              <input
                placeholder="Position"
                onChange={handleChangePosition}
                className={`addWarehouse__position--input${positionError === false ? "" : " input--error"
                  }`}
              />
              <p
                className={`error${positionError === false ? "" : " error--active"
                  }`}
              >
                <img
                  src={errorIcon}
                  alt="error icon"
                  className="error__icon"
                ></img>
                This field is required
              </p>
            </div>
            {/* form phone number input */}
            <div className="addWarehouse__number">
              <h3 className="addWarehouse__label">Phone Number</h3>
              <input
                placeholder="Phone Number"
                // type="number"
                onChange={handleChangeNumber}
                value={number}
                className={`addWarehouse__number--input${numberError === false ? "" : " input--error"
                  }`}
              />
              <p
                className={`error${numberError === false ? "" : " error--active"
                  }`}
              >
                <img
                  src={errorIcon}
                  alt="error icon"
                  className="error__icon"
                ></img>
                This field is required
              </p>
            </div>
            {/* form email input */}
            <div className="addWarehouse__email">
              <h3 className="addWarehouse__label">Email</h3>
              <input
                placeholder="Email"
                onChange={handleChangeEmail}
                className={`addWarehouse__email--input${emailError === false ? "" : " input--error"
                  }`}
              />
              <p
                className={`error${emailError === false ? "" : " error--active"
                  }`}
              >
                <img
                  src={errorIcon}
                  alt="error icon"
                  className="error__icon"
                ></img>
                This field is required
              </p>
              {error && <h2 className="error--active" > <img
                src={errorIcon}
                alt="error icon"
                className="error__icon"
              ></img>{error}</h2>}
            </div>
          </div>
        </div>
        {/* cancel and submit buttons */}
        <div className="addWarehouse__bottom">
          <div className="addWarehouse__buttons">
            <NavLink
              to="/warehouse"
              className="addWarehouse__button addWarehouse__button--cancel"
            >
              Cancel
            </NavLink>
            <input
              type="submit"
              className="addWarehouse__button addWarehouse__button--submit"
              value="+ Add Warehouse"
            />
          </div>
        </div>
      </form>
    </>
  );
}
export default AddWarehouse;
