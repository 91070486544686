import { WHModal } from '../../components/Modal/WarehouseModal';
import './WarehouseList.scss'
import { useState } from 'react'
import chevron from '../../assets/icons/chevron_right-24px.svg'
import delete_outline from '../../assets/icons/delete_outline-24px.svg'
import edit from '../../assets/icons/edit-24px.svg'
import { Link } from 'react-router-dom'

const WarehouseList = ({ warehouse }) => {

	const [showModal, setShowModal] = useState(false)
	const openModal = () => {
		setShowModal(prev => !prev)
	}

	return (
		<div key={warehouse.id} className='warehousespage__list'>
			<div className='warehousespage__container'>
				<div className='warehousespage__card--one'>
					<div className='warehousespage__warehouse'>
						<h2 className='warehousespage__field'>WAREHOUSE</h2>
						<Link className='warehousespage__warehouse-link' to={`/warehouse/${warehouse.id}`}>
							<p className='warehousespage__text--color'>{warehouse.name}</p>
							<img className='warehousespage__warehouse-link-chevron' src={chevron} alt='chevron' />
						</Link>
					</div>
					<div className='warehousespage__address'>
						<h2 className='warehousespage__field'>ADDRESS</h2>
						<p className='warehousespage__text'>{warehouse.address}, {warehouse.city}, {warehouse.country}</p>
					</div>
				</div>
				<div className='warehousespage__card--two'>
					<div className='warehousespage__contact-name'>
						<h2 className='warehousespage__field'>CONTACT NAME</h2>
						<p className='warehousespage__text'>{warehouse.contact.name}</p>
					</div>
					<div className='warehousespage__contact-information'>
						<h2 className='warehousespage__field'>CONTACT INFORMATION</h2>
						<p className='warehousespage__text'>{warehouse.contact.phone}<br />{warehouse.contact.email}</p>
					</div>
				</div>
			</div>
			<div className='warehousespage__actions'>
				<WHModal showModal={showModal} setShowModal={setShowModal} warehouseID={warehouse.id} warehouseName={warehouse.name} />
				<img style={{ cursor: 'pointer' }} src={delete_outline} alt='delete' onClick={openModal} />
				<Link to={`/warehouse/${warehouse.id}/edit`}><img src={edit} alt='edit' /></Link>
			</div>
		</div>
	)
}

export default WarehouseList;