import './InventoryList.scss'
import deleteIcon from '../../assets/images/icons/delete_outline-24px.svg';
import editIcon from '../../assets/images/icons/edit-24px.svg';
import chevronIcon from '../../assets/images/icons/chevron_right-24px.svg';
import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import { InvModal } from '../Modal/InventoryModal';

const InventoryList = ({ product, i }) => {


  const [showModal, setShowModal] = useState(false)
  const openModal = () => {
    setShowModal(prev => !prev);
    
  };


  return (
    <div className={`inventory-list${i === 0 ? ' inventory-list--border' : ''}`}>
      <div className='inventory-list__wrapper'>
        <div className='inventory-list__container inventory-list__container--left'>
          <span className='inventory-list__label'>INVENTORY ITEM</span>
          <Link className='inventory-list__link inventory-list__link--width' to={`/inventory/${product.id}`}>
            <span className='inventory-list__product inventory-list__product--color'>{product.itemName}</span>
            <img className='inventory-list__product-chevron' src={chevronIcon} alt="chevron right" />
          </Link>
          <span className='inventory-list__label'>CATEGORY</span>
          <span className='inventory-list__product inventory-list__product--category-width'>{product.category}</span>
        </div>
        <div className='inventory-list__container inventory-list__container--right'>
          <span className='inventory-list__label'>STATUS</span>
          <div className='inventory-list__stock-container'>
            <span className={`inventory-list__product${product.quantity === 0 ? ' inventory-list__product--out' : ' inventory-list__product--in'}`}>{product.status.toUpperCase()}</span>
          </div>
          <span className='inventory-list__label'>QTY</span>
          <span className='inventory-list__product inventory-list__product--quantity-width'>{product.quantity}</span>
          <span className='inventory-list__label'>WAREHOUSE</span>
          <span className='inventory-list__product inventory-list__product--name-width'>{product.warehouseName}</span>
        </div>
      </div>
      <div className='inventory-list__button-container'>
        <InvModal showModal={showModal} setShowModal={setShowModal} productID={product.id} productName={product.itemName} />
          <img style={{cursor: 'pointer'}} className='inventory-list__icon inventory-list__icon--margin' onClick={openModal} src={deleteIcon} alt="delete" />
        <Link className='inventory-list__link' to={`/inventory/${product.id}/edit`}>
          <img className='inventory-list__icon' src={editIcon} alt="edit" />
        </Link>
      </div>
    </div>
  )
}

export default InventoryList