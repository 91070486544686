import "./Header.scss";
import logo from "../../assets/images/logos/InStock-Logo_2x.png";
import { NavLink } from "react-router-dom";

const Header = () => {
  return (
    <header className="nav">
      <NavLink to="/warehouse" className="nav__link">
        <img src={logo} className="nav__logo" alt="logo" />
      </NavLink>
      <div className="nav__items">
        <NavLink to="/warehouse" className="nav__item nav__item--warehouses">
          Warehouses
        </NavLink>
        <NavLink to="/inventory" className="nav__item nav__item--inventory">
          Inventory
        </NavLink>
      </div>
    </header>
  );
};

export default Header;
