import "./EditWarehouse.scss";
import leftArrow from "../../assets/images/icons/arrow_back-24px.svg";
import errorIcon from "../../assets/images/icons/error-24px.svg";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useParams, NavLink } from "react-router-dom";

const API_URL = process.env.REACT_APP_API_URL;

function formatPhoneNumber(value) {
  // if input value is falsy eg if the user deletes the input, then just return
  if (!value) return value;

  const phoneNumber = value.replace(/[^\d]/g, "");
  const phoneNumberLength = phoneNumber.length;

  if (phoneNumberLength < 2) return `+${phoneNumber.slice(0)} `;
  if (phoneNumberLength < 8) {
    return `+${phoneNumber.slice(0, 1)} (${phoneNumber.slice(
      1,
      4
    )}) ${phoneNumber.slice(4)}`;
  }
  return `+${phoneNumber.slice(0, 1)} (${phoneNumber.slice(
    1,
    4
  )}) ${phoneNumber.slice(4, 7)}-${phoneNumber.slice(7, 11)}`;
}

function EditWarehouse() {
  const navigate = useNavigate();
  let { id } = useParams();

  //states for various inputs
  const [warehouseName, setWarehouseName] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [contactName, setContactName] = useState("");
  const [position, setPosition] = useState("");
  const [number, setNumber] = useState("");
  const [email, setEmail] = useState("");

  //retriving data to populate form
  let warehouseID = id;

  useEffect(() => {
    axios
      .get(`${API_URL}/warehouse/${warehouseID}`)
      .then((response) => {
        setWarehouseName(response.data.name);
        setAddress(response.data.address);
        setCity(response.data.city);
        setCountry(response.data.country);
        setContactName(response.data.contact.name);
        setPosition(response.data.contact.position);
        setNumber(response.data.contact.phone);
        setEmail(response.data.contact.email);
      });
  }, [warehouseID]);

  //validating the email
  const [error, setError] = useState(null);
  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  // Checking if the fields are all filled

  const isFormValid = () => {
    if (
      !warehouseName ||
      !address ||
      !city ||
      !country ||
      !contactName ||
      !position ||
      !number ||
      !email
    ) {
      return false;
    }
    return true;
  };

  //checking if individual forms are filled for validation
  const isWarehouseNameValid = () => {
    if (!warehouseName) {
      return false;
    }
    return true;
  };

  const isAddressValid = () => {
    if (!address) {
      return false;
    }
    return true;
  };

  const isCityValid = () => {
    if (!city) {
      return false;
    }
    return true;
  };

  const isCountryValid = () => {
    if (!country) {
      return false;
    }
    return true;
  };

  const isContactNameValid = () => {
    if (!contactName) {
      return false;
    }
    return true;
  };

  const isPositionValid = () => {
    if (!position) {
      return false;
    }
    return true;
  };

  const isNumberValid = () => {
    if (!number) {
      return false;
    }
    return true;
  };

  const isEmailValid = () => {
    if (!email) {
      return false;
    }
    return true;
  };

  const handlePhoneInput = (e) => {
    const formattedPhoneNumber = formatPhoneNumber(e.target.value);
    setNumber(formattedPhoneNumber);
  };

  const handleChangeEmail = (event) => {
    if (!isValidEmail(event.target.value)) {
      setError('Email is invalid');
    } else {
      setError(null)
    }
    setEmail(event.target.value)
  };

  //data that'll be sent to axios
  const editWarehouse = {
    id: id,
    name: warehouseName,
    address: address,
    city: city,
    country: country,
    contact: {
      name: contactName,
      position: position,
      phone: number,
      email: email,
    },
  };

  //submitting the updates
  const handleSubmit = (event) => {
    event.preventDefault();
    if (isFormValid()) {
      axios
        .put(`${API_URL}/warehouse/${warehouseID}`, editWarehouse)
        .then((response) => {
          alert("SUCCESS");
        })
        .then(navigate("/warehouse"))
        .catch((error) => {
          alert("ERROR FILL OUT FORM");
        });
    }
  };

  return (
    <>
      {/* form begins here */}
      <form className="editWarehouse" onSubmit={handleSubmit}>
        <div className="editWarehouse__headingcontainer">
          <NavLink to="/warehouse">
            <img src={leftArrow} alt="left arrow" />
          </NavLink>
          <h1 className="editWarehouse__header">Edit Warehouse</h1>
        </div>
        <div className="editWarehouse__form">
          <div className="editWarehouse__detail">
            <h2 className="editWarehouse__subheading">Warehouse Details</h2>
            {/* form name input */}
            <div className="editWarehouse__name">
              <h3 className="editWarehouse__label"> Warehouse Name</h3>
              <input
                className={`editWarehouse__name--input${isWarehouseNameValid() ? "" : " input--error"
                  }`}
                value={warehouseName}
                onChange={(event) => setWarehouseName(event.target.value)}
              />
              <p
                className={`error${isWarehouseNameValid() ? "" : " error--active"
                  }`}
              >
                <img
                  src={errorIcon}
                  alt="error icon"
                  className="error__icon"
                ></img>
                This field is required
              </p>
            </div>
            {/* form address input */}
            <div className="editWarehouse__address">
              <h3 className="editWarehouse__label"> Street address</h3>
              <input
                className={`editWarehouse__address--input${isAddressValid() ? "" : " input--error"
                  }`}
                placeholder="Street address"
                value={address}
                onChange={(event) => setAddress(event.target.value)}
              />
              <p className={`error${isAddressValid() ? "" : " error--active"}`}>
                <img
                  src={errorIcon}
                  alt="error icon"
                  className="error__icon"
                ></img>
                This field is required
              </p>
            </div>
            {/* form city input */}
            <div className="editWarehouse__city">
              <h3 className="editWarehouse__label">City</h3>
              <input
                placeholder="City"
                value={city}
                onChange={(event) => setCity(event.target.value)}
                className={`editWarehouse__city--input${isCityValid() ? "" : " input--error"
                  }`}
              />
              <p className={`error${isCityValid() ? "" : " error--active"}`}>
                <img
                  src={errorIcon}
                  alt="error icon"
                  className="error__icon"
                ></img>
                This field is required
              </p>
            </div>
            {/* form country input */}
            <div className="editWarehouse__country">
              <h3 className="editWarehouse__label">Country</h3>
              <input
                placeholder="Country"
                value={country}
                onChange={(event) => setCountry(event.target.value)}
                className={`editWarehouse__country--input${isCountryValid() ? "" : " input--error"
                  }`}
              />
              <p className={`error${isCountryValid() ? "" : " error--active"}`}>
                <img
                  src={errorIcon}
                  alt="error icon"
                  className="error__icon"
                ></img>
                This field is required
              </p>
            </div>
          </div>
          {/* form contact input */}
          <div className="editWarehouse__contact">
            <h2 className="editWarehouse__subheading">Contact Details</h2>
            <div className="editWarehouse__contactName">
              <h3 className="editWarehouse__label">Contact Name</h3>
              <input
                placeholder="Name"
                value={contactName}
                onChange={(event) => setContactName(event.target.value)}
                className={`editWarehouse__contactName--input${isContactNameValid() ? "" : " input--error"
                  }`}
              />
              <p
                className={`error${isContactNameValid() ? "" : " error--active"
                  }`}
              >
                <img
                  src={errorIcon}
                  alt="error icon"
                  className="error__icon"
                ></img>
                This field is required
              </p>
            </div>
            {/* form position input */}
            <div className="editWarehouse__position">
              <h3 className="editWarehouse__label"> Position</h3>
              <input
                placeholder="Position"
                value={position}
                onChange={(event) => setPosition(event.target.value)}
                className={`editWarehouse__position--input${isPositionValid() ? "" : " input--error"
                  }`}
              />
              <p
                className={`error${isPositionValid() ? "" : " error--active"}`}
              >
                <img
                  src={errorIcon}
                  alt="error icon"
                  className="error__icon"
                ></img>
                This field is required
              </p>
            </div>
            {/* form phone number input */}
            <div className="editWarehouse__number">
              <h3 className="editWarehouse__label">Phone Number</h3>
              <input
                placeholder="Phone Number"
                onChange={(e) => handlePhoneInput(e)}
                value={number}
                className={`editWarehouse__number--input${isNumberValid() ? "" : " input--error"
                  }`}
              />
              <p className={`error${isNumberValid() ? "" : " error--active"}`}>
                <img
                  src={errorIcon}
                  alt="error icon"
                  className="error__icon"
                ></img>
                This field is required
              </p>
            </div>
            {/* form email input */}
            <div className="editWarehouse__email">
              <h3 className="editWarehouse__label">Email</h3>
              <input
                placeholder="Email"
                value={email}
                onChange={handleChangeEmail}
                className={`editWarehouse__email--input${isEmailValid() ? "" : " input--error"
                  }`}
              />
              <p className={`error${isEmailValid() ? "" : " error--active"}`}>
                <img
                  src={errorIcon}
                  alt="error icon"
                  className="error__icon"
                ></img>
                This field is required
              </p>
              {error && <h2 className="error--active" > <img
                src={errorIcon}
                alt="error icon"
                className="error__icon"
              ></img>{error}</h2>}
            </div>
          </div>
        </div>
        {/* cancel and submit buttons */}
        <div className="editWarehouse__bottom">
          <div className="editWarehouse__buttons">
            <NavLink
              to="/warehouse"
              className="editWarehouse__button editWarehouse__button--cancel"
            >
              Cancel
            </NavLink>
            <input
              type="submit"
              className="editWarehouse__button editWarehouse__button--submit"
              value="Save"
            />
          </div>
        </div>
      </form>
    </>
  );
}
export default EditWarehouse;
