import './WarehousePage.scss';
import { Link } from 'react-router-dom';
import axios from 'axios';
import sort from '../../assets/icons/sort-24px.svg';
import { useState, useEffect } from 'react';
import { v4 as uuidv4 } from "uuid";
import WarehouseList from '../../components/WarehouseList/WarehouseList';

const API_URL = process.env.REACT_APP_API_URL;

function WarehousePage() {

	let [warehouses, setWarehouses] = useState(null)

	useEffect(() => {
		const url = `${API_URL}/warehouse`;
		axios.get(url)
			.then(response => {
				setWarehouses(response.data)
			})
	}, [])

	return (
		<>
			<div className='warehousespage'>
				<div className='warehousespage__top'>
					<h1 className='warehousespage__title'>Warehouses</h1>
					<form className='warehousespage__form'>
						<input className='warehousespage__search' type={'search'} placeholder='Search...' />
						<Link to={`/warehouse/add`}><button style={{ cursor: 'pointer' }} className='warehousespage__button '>+ Add New Warehouse</button></Link>
					</form>
				</div>
				<div className='warehousespage__field--tablet'>
					<div className='warehousespage__warehouse--tablet'>
						<h3 className='warehousespage__tableheader'>WAREHOUSE </h3>
						<img className='warehousespage__sort' src={sort} alt='sort' />
					</div>
					<div className='warehousespage__address--tablet'>
						<h3 className='warehousespage__tableheader'>ADDRESS</h3>
						<img className='warehousespage__sort' src={sort} alt='sort' />
					</div>
					<div className='warehousespage__contact-name--tablet'>
						<h3 className='warehousespage__tableheader'>CONTACT NAME</h3>
						<img className='warehousespage__sort' src={sort} alt='sort' />
					</div>
					<div className='warehousespage__contact-information--tablet'>
						<h3 className='warehousespage__tableheader'>CONTACT INFOMRATION</h3>
						<img className='warehousespage__sort' src={sort} alt='sort' />
					</div>
					<div className='warehousespage__actions--tablet'>
						<h3 className='warehousespage__tableheader'>ACTIONS</h3>
					</div>
				</div>
				{warehouses ? warehouses.map((warehouse) => {
					return (
						<WarehouseList warehouse={warehouse} key={uuidv4()}></WarehouseList>)
				}) : <h1>Loading...</h1>}
			</div>
		</>
	);
}

export default WarehousePage
