import "./WarehouseDetailsPage.scss";
import backIcon from "../../assets/images/icons/arrow_back-24px.svg";
import editIcon from "../../assets/images/icons/edit-white-24px.svg";
import sortIcon from "../../assets/images/icons/sort-24px.svg";
import WarehouseDetailsList from "../../components/WarehouseDetailsList/WarehouseDetailsList";
import { React, useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";

const API_URL = process.env.REACT_APP_API_URL;

const WarehouseDetailsPage = () => {
  const [currentWarehouse, setCurrentWarehouse] = useState(null);
  const [currentInventory, setCurrentInventory] = useState(null);

  const warehouseId = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    const url = `${API_URL}/warehouse/${warehouseId.id}`;
    axios.get(url)
      .then(response => {
        setCurrentWarehouse(response.data);
      })
      .then((_response) => {
        const url = `${API_URL}/inventory/warehouse/${warehouseId.id}`;
        axios
          .get(url)
          .then((response) => {
            setCurrentInventory(response.data);
          })
          .catch((error) => {
            if (error.response) {
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              console.log(error.request);
            } else {
              console.log("Error", error.message);
            }
          });
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }
      });
  }, [warehouseId.id]);

  if (!currentWarehouse || !currentInventory) {
    return <h1>Loading...</h1>;
  }

  return (
    <section className="warehouse-details">
      <div className="warehouse-details__title-container">
        <div className="warehouse-details__title-wrapper">
          <Link className="warehouse-details__link" to="/">
            <img
              className="warehouse-details__icon warehouse-details__icon--spacing"
              src={backIcon}
              alt="back"
            />
          </Link>
          <h1 className="warehouse-details__title">{currentWarehouse.name}</h1>
        </div>
        <div className="warehouse-details__button-container">
          <button
            style={{ cursor: 'pointer' }}
            onClick={() => navigate(`/warehouse/${currentWarehouse.id}/edit`)}
            className="warehouse-details__link warehouse-details__link--color"
            to="/"
          >
            <img
              className="warehouse-details__icon warehouse-details__icon--color"
              src={editIcon}
              alt="edit"
            />
            <span className="warehouse-details__icon-text">Edit</span>
          </button>
        </div>
      </div>
      <div className="warehouse-details__contact-container">
        <div className="warehouse-details__address-container">
          <h2 className="warehouse-details__label">WAREHOUSE ADDRESS:</h2>
          <p className="warehouse-details__body-copy warehouse-details__body-copy--breakpoint">
            {currentWarehouse.address}, {currentWarehouse.city},{" "}
            {currentWarehouse.country}
          </p>
          <p className="warehouse-details__body-copy-tablet">
            {currentWarehouse.address},<br />
            {currentWarehouse.city}, {currentWarehouse.country}
          </p>
        </div>
        <div className="warehouse-details__contact-wrapper">
          <div className="warehouse-details__content-spacing">
            <h2 className="warehouse-details__label">CONTACT NAME:</h2>
            <p className="warehouse-details__body-copy">
              {currentWarehouse.contact.name}
              <br />
              {currentWarehouse.contact.position}
            </p>
          </div>
          <div className="warehouse-details__content-spacing">
            <h2 className="warehouse-details__label">CONTACT INFORMATION:</h2>
            <p className="warehouse-details__body-copy">
              {currentWarehouse.contact.phone}
              <br />
              {currentWarehouse.contact.email}
            </p>
          </div>
        </div>
      </div>
      <div className="warehouse-details__table-row">
        <div className="warehouse-details__row-wrapper">
          <div className="warehouse-details__table-head warehouse-details__table-head--pos1">
            <h3 className="warehouse-details__head-copy">INVENTORY ITEM</h3>
            <img
              className="warehouse-details__sort-icon"
              src={sortIcon}
              alt="sort"
            />
          </div>
          <div className="warehouse-details__table-head warehouse-details__table-head--pos2">
            <h3 className="warehouse-details__head-copy">CATEGORY</h3>
            <img
              className="warehouse-details__sort-icon"
              src={sortIcon}
              alt="sort"
            />
          </div>
          <div className="warehouse-details__table-head warehouse-details__table-head--pos3">
            <h3 className="warehouse-details__head-copy">STATUS</h3>
            <img
              className="warehouse-details__sort-icon"
              src={sortIcon}
              alt="sort"
            />
          </div>
          <div className="warehouse-details__table-head warehouse-details__table-head--pos4">
            {/* Hide/display based on screensize for the 2 different versions of quantity */}
            <h3 className="warehouse-details__head-copy warehouse-details__head-copy--tablet">
              QUANTITY
            </h3>
            <h3 className="warehouse-details__head-copy warehouse-details__head-copy--desktop">
              QTY
            </h3>
            <img
              className="warehouse-details__sort-icon"
              src={sortIcon}
              alt="sort"
            />
          </div>
        </div>
        <div className="warehouse-details__table-head warehouse-details__table-head--pos5">
          <h3 className="warehouse-details__head-copy">ACTIONS</h3>
        </div>
      </div>
      {currentInventory.map((product, i) => {
        return <WarehouseDetailsList i={i} product={product} key={uuidv4()} />;
      })}
    </section>
  );
};

export default WarehouseDetailsPage;
