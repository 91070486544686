import './EditInventory.scss';
import leftArrow from '../../assets/images/icons/arrow_back-24px.svg'
import { NavLink, useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import axios from 'axios';
import { useEffect } from 'react';
import errorIcon from "../../assets/images/icons/error-24px.svg";

const API_URL = process.env.REACT_APP_API_URL;

const EditInventory = () => {
  const navigate = useNavigate();
  let { id } = useParams();

  //getting data from axios for warehouse
  const [warehouseData, setWarehouseData] = useState([]);

  //setting states for individual inputs
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [category, setCategory] = useState('');
  const [status, setStatus] = useState('');
  const [warehouse, setWarehouse] = useState('');

  //handle change for item name
  const handleChangeName = (event) => {
    setName(event.target.value);
  };

  //handle change for description
  const handleChangeDescription = (event) => {
    setDescription(event.target.value);
  };

  //handle change for category
  const handleChangeCategory = (event) => {
    setCategory(event.target.value);
  };

  //handle change for status
  const handleChangeStatus = (event) => {
    setStatus(event.target.value);
  };

  //handle change for warehouses
  const handleChangeWarehouse = (event) => {
    setWarehouse(event.target.value);
  };

  //Checking to see if inputs are valid
  const isNameValid = () => {
    if (name.length < 1) {
      return false;
    }
    return true;
  };

  const isDescriptionValid = () => {
    if (description.length < 1) {
      return false;
    }
    return true;
  };

  const isCategoryValid = () => {
    if (category === '') {
      return false;
    }
    return true;
  };

  //check to see if all forms are valid
  const isFormValid = () => {
    //Check if the fields are all filled
    if (!name || !description || !category) {
      return false;
    }
    //Check if the name field is valid
    if (!isNameValid()) {
      return false;
    }
    //Check if the description field is valid
    if (!isDescriptionValid()) {
      return false;
    }
    if (!isCategoryValid()) {
      return false;
    }

    return true;
  };


  //submit form and change page but inputs must be filled
  const submitForm = (event) => {
    event.preventDefault();
    if (isFormValid()) {
      axios
        .put(`${API_URL}/inventory/${currentId}`, {
          itemName: name,
          description: description,
          category: category,
          status: status,
          warehouseName: warehouse,
        })
        .then((response) => console.log(response.data));
      alert('Inventory updated!');
      navigate(`/inventory/`);
    } else {
      alert('Please fill up all inputs');
    }
  };

  //getting data for warehouses from database
  useEffect(() => {
    axios
      .get(`${API_URL}/warehouse`)
      .then((response) => {
        setWarehouseData(response.data);
      })
      .catch((err) => console.log(err.data));
  }, []);

  //make get request with axios
  let currentId = id;
  useEffect(() => {
    axios
      .get(`${API_URL}/inventory/${currentId}`)
      .then((response) => {
        // console.log(response.data[0].itemName)
        setName(response.data[0].itemName);
        setDescription(response.data[0].description);
        setCategory(response.data[0].category);
        setStatus(response.data[0].status);
        setWarehouse(response.data[0].warehouseName);
      })
      .catch((err) => console.log(err));
  }, [currentId]);

  //return back when you cancel edit inventory
  const returnBack = () => {
    navigate(-1);
  };

  return (
    <>
      <div className="background__top"></div>
      <div className="background__bottom"></div>
      <div className="editinventory">
        <div className="editinventory__headingcontainer">
          <img src={leftArrow} alt="left arrow" onClick={returnBack} className="editinventory__navicon" />
          <h1 className="editinventory__header">Edit Inventory Item</h1>
        </div>
        <form className="editinventory__form" onSubmit={submitForm}>
          <div className="editinventory__formcontainer">
            <div className="editinventory__topform">
              <h2 className="editinventory__subheading">Item Details</h2>

              {/* Name */}
              <label htmlFor="itemName" className="editinventory__label">
                Item Name
              </label>
              <input
                type="text"
                name="itemName"
                className={`editinventory__name${isNameValid() ? '' : ' editinventory__name--error'
                  }`}
                placeholder="Item Name"
                value={name}
                onChange={handleChangeName}
              ></input>
              <p className={`error${isNameValid() ? '' : ' error--active'}`}>
                <img
                  src={errorIcon}
                  alt="error icon"
                  className="error__icon"
                ></img>
                This field is required
              </p>

              {/* Description */}
              <label htmlFor="itemDescription" className="editinventory__label">
                Description
              </label>
              <textarea
                type="text"
                name="itemDescription"
                className={`editinventory__description${isDescriptionValid()
                  ? ''
                  : ' editinventory__description--error'
                  }`}
                placeholder="Please enter a brief item description..."
                value={description}
                onChange={handleChangeDescription}
              ></textarea>
              <p
                className={`error${isDescriptionValid() ? '' : ' error--active'
                  }`}
              >
                <img
                  src={errorIcon}
                  alt="error icon"
                  className="error__icon"
                ></img>
                This field is required
              </p>

              {/* Category */}
              <div className="editinventory__select">
                <label htmlFor="itemCategory" className="editinventory__label">
                  Category
                </label>
                <select
                  name="itemCategory"
                  className={`editinventory__category${isCategoryValid() ? '' : ' editinventory__category--error'
                    }`}
                  value={category}
                  onChange={handleChangeCategory}
                >
                  <option value="">Please select</option>
                  <option value="Electronics">Electronics</option>
                  <option value="Gear">Gear</option>
                  <option value="Apparel">Apparel</option>
                  <option value="Accessories">Accessories</option>
                  <option value="Health">Health</option>
                </select>
                <span className="editinventory__arrow"></span>
                <p
                  className={`error${isCategoryValid() ? '' : ' error--active'
                    }`}
                >
                  <img
                    src={errorIcon}
                    alt="error icon"
                    className="error__icon"
                  ></img>
                  This field is required
                </p>
              </div>
            </div>

            <div className="editinventory__bottomform">
              <h2 className="editinventory__subheading">Item Availability</h2>
              <h3 className="editinventory__itemheading">Status</h3>

              <div className="editinventory__statuscontainer">
                {/* in stock */}
                <div className="editinventory__radiocontainer">
                  <input
                    type="radio"
                    name="stock"
                    id="inStock"
                    className="editinventory__radio"
                    value="In Stock"
                    checked={status === 'In Stock'}
                    onChange={handleChangeStatus}
                  ></input>
                  <label
                    htmlFor="inStock"
                    className="editinventory__radiolabel"
                  >
                    In stock
                  </label>
                </div>
                {/* out of stock*/}
                <div className="editinventory__radiocontainer">
                  <input
                    type="radio"
                    name="stock"
                    id="outOfStock"
                    className="editinventory__radio"
                    value="Out of Stock"
                    checked={status === 'Out of Stock'}
                    onChange={handleChangeStatus}
                  ></input>
                  <label
                    htmlFor="outOfStock"
                    className="editinventory__radiolabel"
                  >
                    Out of stock
                  </label>
                </div>
              </div>
              {/* warehouse drop down*/}
              <div className="editinventory__select">
                <label
                  htmlFor="itemCategory"
                  className="editinventory__itemheading"
                >
                  Warehouse
                </label>
                <select
                  name="itemCategory"
                  className="editinventory__category"
                  value={warehouse}
                  onChange={handleChangeWarehouse}
                >
                  {warehouseData.map((ware) => {
                    return (
                      <option value={ware.name} key={ware.id}>
                        {ware.name}
                      </option>
                    );
                  })}
                </select>
                <span className="editinventory__arrow"></span>
              </div>
            </div>
          </div>
          {/* buttons*/}
          <div className="editinventory__buttons">
            <NavLink to="/inventory" className="editinventory__cancel">Cancel</NavLink>
            <button input='submit' className="editinventory__save" value="save">Save</button>
          </div>
        </form>
      </div>
    </>
  );
}


export default EditInventory;