import './App.scss';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import WarehousePage from './pages/WarehousePage/WarehousePage';
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import AddWarehouse from './components/AddNewWarehouse/AddNewWarehouse';
import EditWarehouse from './components/EditWarehouse/EditWarehouse';
import EditInventory from './components/EditInventory/EditInventory';
import WarehouseDetailsPage from './pages/WarehouseDetailsPage/WarehouseDetailsPage';
import AddInventory from './components/AddInventory/AddInventory';
import InventoryPage from './pages/InventoryPage/InventoryPage';
import InventoryItem from './components/InventoryItem/InventoryItem';
import ErrorPage from './pages/ErrorPage/ErrorPage';

const App = () => {
  return (
    <div className="App">
      <BrowserRouter>
        <div>
          <Header />
          <Routes>
            <Route path='/' element={<Navigate to='/warehouse' />} />
            <Route path='/warehouse' element={<WarehousePage />} />
            <Route path="/warehouse/:id" element={<WarehouseDetailsPage />} />
            <Route path="/warehouse/:id/edit" element={<EditWarehouse />} />
            <Route path="/warehouse/add" element={<AddWarehouse />} />
            <Route path="/inventory" element={<InventoryPage />} />
            <Route path="/inventory/:id" element={<InventoryItem />} />
            <Route path="/inventory/:id/edit" element={<EditInventory />} />
            <Route path="/inventory/add" element={<AddInventory />} />
            <Route path="*" element={<ErrorPage />} />
          </Routes>
        </div>
        <Footer />
      </BrowserRouter>
    </div>
  );
};

export default App;
