import './AddInventory.scss';
import leftArrow from '../../assets/images/icons/arrow_back-24px.svg';
import errorIcon from '../../assets/images/icons/error-24px.svg';
import { useNavigate, NavLink } from 'react-router-dom';
import { useEffect, useState } from 'react';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

const AddInventory = () => {
  const navigate = useNavigate();

  //getting data from axios for warehouse
  const [warehouseData, setWarehouseData] = useState([]);

  //setting states for individual inputs
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [category, setCategory] = useState('');
  const [status, setStatus] = useState('');
  const [quantity, setQuantity] = useState(0);
  const [warehouse, setWarehouse] = useState('');

  //validating errors of all forms
  const [nameError, setNameError] = useState(false);
  const [descriptionError, setDescriptionError] = useState(false);
  const [categoryError, setCategoryError] = useState(false);
  const [statusError, setStatusError] = useState(false);
  const [quantityError, setQuantityError] = useState(false);
  const [warehouseError, setWarehouseError] = useState(false);

  //handle change for item name
  const handleChangeName = (event) => {
    setName(event.target.value);
    if (event.target.value < 1) {
      setNameError(true);
    } else {
      setNameError(false);
    }
  };

  //handle change for description
  const handleChangeDescription = (event) => {
    setDescription(event.target.value);
    if (event.target.value < 1) {
      setDescriptionError(true);
    } else {
      setDescriptionError(false);
    }
  };

  //handle change for category
  const handleChangeCategory = (event) => {
    setCategory(event.target.value);
    if (event.target.value === '') {
      setCategoryError(true);
    } else {
      setCategoryError(false);
    }
  };

  //handle change for status
  const handleChangeStatus = (event) => {
    setStatus(event.target.value);
    if (event.target.value === '') {
      setStatusError(true);
    } else {
      setStatusError(false);
    }
  };

  //handle change for quantity
  const handleChangeQuantity = (event) => {
    setQuantity(event.target.value);
    if (event.target.value === 0 || event.target.value === '') {
      setQuantityError(true);
    } else {
      setQuantityError(false);
    }
  };

  //handle change for warehouses
  const handleChangeWarehouse = (event) => {
    setWarehouse(event.target.value);
    if (event.target.value === '') {
      setWarehouseError(true);
    } else {
      setWarehouseError(false);
    }
  };

  //Checking to see if inputs are valid
  const isNameValid = () => {
    if (name.length < 1) {
      return false;
    }
    return true;
  };

  const isDescriptionValid = () => {
    if (description.length < 1) {
      return false;
    }
    return true;
  };

  const isCategoryValid = () => {
    if (category === '') {
      return false;
    }
    return true;
  };

  const isStatusValid = () => {
    if (status === '') {
      return false;
    }
    return true;
  };

  const isWarehouseValid = () => {
    if (warehouse === '') {
      return false;
    }
    return true;
  };

  const isQuantityValid = () => {
    if (quantity === '') {
      return false;
    }
    return true;
  };

  //check to see if all forms are filled
  const isFormValid = () => {
    if (
      !name ||
      !description ||
      !category ||
      !status ||
      !warehouse ||
      !quantity
    ) {
      return false;
    }

    return true;
  };

  //submit form and change page but inputs must be filled
  const submitForm = (event) => {
    event.preventDefault();
    if (isFormValid()) {
      axios
        .post(`${API_URL}/inventory`, {
          itemName: name,
          description: description,
          category: category,
          status: status,
          quantity: quantity,
          warehouseName: warehouse,
        })
        .then((response) => console.log(response.data));
      alert('New inventory added!');
      navigate('/inventory');
    } else {
      if (!isNameValid()) {
        setNameError(true)
      }
      if (!isDescriptionValid()) {
        setDescriptionError(true)
      }
      if (!isCategoryValid()) {
        setCategoryError(true)
      }
      if (!isStatusValid()) {
        setStatusError(true)
      }
      if (!isQuantityValid()) {
        setQuantityError(true)
      }
      if (!isWarehouseValid()) {
        setWarehouseError(true)
      }
      alert('Please fill up all inputs');
    }
  };

  useEffect(() => {
    axios
      .get(`${API_URL}/warehouse`)
      .then((response) => {
        setWarehouseData(response.data);
      })
      .catch((err) => console.log(err.data));
  }, []);

  //return back when you cancel edit inventory
  const returnBack = () => {
    navigate(-1);
  };

  return (
    <>
      <div className="addinventory">
        <div className="addinventory__headingcontainer">
          <img
            src={leftArrow}
            alt="left arrow"
            onClick={returnBack}
            className="addinventory__navicon"
          />
          <h1 className="addinventory__header">Add New Inventory Item</h1>
        </div>
        <form className="addinventory__form" onSubmit={submitForm}>
          <div className="addinventory__formcontainer">
            <div className="addinventory__topform">
              <h2 className="addinventory__subheading">Item Details</h2>

              {/* Name */}
              <label htmlFor="itemName" className="addinventory__label">
                Item Name
              </label>
              <input
                type="text"
                name="itemName"
                className={`editinventory__name${nameError === false ? '' : ' editinventory__name--error'
                  }`}
                value={name}
                onChange={handleChangeName}
                placeholder="Item Name"
              ></input>
              <p
                className={`error${nameError === false ? '' : ' error--active'
                  }`}
              >
                <img
                  src={errorIcon}
                  alt="error icon"
                  className="error__icon"
                ></img>
                This field is required
              </p>

              {/* Description */}
              <label htmlFor="itemDescription" className="addinventory__label">
                Description
              </label>
              <textarea
                type="text"
                name="itemDescription"
                className={`editinventory__description${descriptionError === false
                  ? ''
                  : ' editinventory__description--error'
                  }`}
                placeholder="Please enter a brief item description..."
                value={description}
                onChange={handleChangeDescription}
              ></textarea>
              <p
                className={`error${descriptionError === false ? '' : ' error--active'
                  }`}
              >
                <img
                  src={errorIcon}
                  alt="error icon"
                  className="error__icon"
                ></img>
                This field is required
              </p>

              {/* Category */}
              <div className="addinventory__select">
                <label htmlFor="itemCategory" className="editinventory__label">
                  Category
                </label>
                <select
                  name="itemCategory"
                  className={`editinventory__category${categoryError === false
                    ? ''
                    : ' editinventory__category--error'
                    }`}
                  value={category}
                  onChange={handleChangeCategory}
                >
                  <option value="">Please select</option>
                  <option value="Electronics">Electronics</option>
                  <option value="Gear">Gear</option>
                  <option value="Apparel">Apparel</option>
                  <option value="Accessories">Accessories</option>
                  <option value="Health">Health</option>
                </select>
                <span className="addinventory__arrow"></span>
                <p
                  className={`error${categoryError === false ? '' : ' error--active'
                    }`}
                >
                  <img
                    src={errorIcon}
                    alt="error icon"
                    className="error__icon"
                  ></img>
                  This field is required
                </p>
              </div>
            </div>

            <div className="addinventory__bottomform">
              <h2 className="addinventory__subheading">Item Availability</h2>
              <h3 className="addinventory__itemheading">Status</h3>
              <div className="addinventory__statuscontainer">
                {/* in stock */}
                <div className="addinventory__radiocontainer">
                  <input
                    type="radio"
                    name="stock"
                    value="In stock"
                    className="addinventory__radio"
                    onChange={handleChangeStatus}
                  ></input>
                  <label htmlFor="stock" className="addinventory__radiolabel">
                    In stock
                  </label>
                </div>
                {/* out of stock*/}
                <div className="addinventory__radiocontainer">
                  <input
                    type="radio"
                    name="stock"
                    value="Out of stock"
                    className="addinventory__radio"
                    onChange={handleChangeStatus}
                  ></input>
                  <label htmlFor="stock" className="addinventory__radiolabel">
                    Out of stock
                  </label>
                </div>
              </div>
              <p
                className={`error${statusError === false ? '' : ' error--active'
                  }`}
              >
                <img
                  src={errorIcon}
                  alt="error icon"
                  className="error__icon"
                ></img>
                This field is required
              </p>

              {/* Item quantity */}
              <label htmlFor="itemQuantity" className="addinventory__label">
                Quantity
              </label>
              <input
                type="number"
                name="itemQuantity"
                className={`editinventory__name${quantityError === false ? '' : ' editinventory__name--error'
                  }`}
                placeholder="write a number"
                onChange={handleChangeQuantity}
                value={quantity}
              ></input>
              <p
                className={`error${quantityError === false ? '' : ' error--active'
                  }`}
              >
                <img
                  src={errorIcon}
                  alt="error icon"
                  className="error__icon"
                ></img>
                This field is required
              </p>

              {/* warehouse drop down*/}
              <div className="addinventory__select">
                <label
                  htmlFor="Warehouse"
                  className="addinventory__itemheading"
                >
                  Warehouse
                </label>
                <select
                  name="Warehouse"
                  className={`editinventory__category${warehouseError === false
                    ? ''
                    : ' editinventory__category--error'
                    }`}
                  value={warehouse}
                  onChange={handleChangeWarehouse}
                >
                  <option value="">Please select</option>
                  {warehouseData.map((ware) => {
                    return (
                      <option value={ware.name} key={ware.id}>
                        {ware.name}
                      </option>
                    );
                  })}
                </select>
                <span className="addinventory__arrow"></span>
                <p
                  className={`error${warehouseError === false ? '' : ' error--active'
                    }`}
                >
                  <img
                    src={errorIcon}
                    alt="error icon"
                    className="error__icon"
                  ></img>
                  This field is required
                </p>
              </div>
            </div>
          </div>
          {/* buttons*/}
          <div className="addinventory__buttons">
            <NavLink to="/inventory" className="addinventory__cancel">
              Cancel
            </NavLink>
            <button className="addinventory__add">+ Add Item</button>
          </div>
        </form>
      </div>
    </>
  );
}

export default AddInventory;