import "./Modal.scss";
import React from "react";
import axios from "axios";
import { useRef } from "react";
import exit from "../../assets/icons/close-24px.svg";

const API_URL = process.env.REACT_APP_API_URL;

export const WHModal = ({ showModal, setShowModal, warehouseID, warehouseName }) => {
  const modalRef = useRef()
  const closeModal = (e) => {
    if (modalRef.current === e.target) {
      setShowModal(false);
    }
  };

  const deleteHandler = () => {
    console.log(warehouseID)
    axios
      .delete(`${API_URL}/warehouse/${warehouseID}`)
      .then((response) => {
        console.log(response);
        window.location.reload()
      })
      .catch((error) => {
        console.log(error);
      });
    window.location.reload()
  };

  return (
    <>
      {showModal ? (
        <div className="background" ref={modalRef} onClick={closeModal}>
          <div className="modal">
            <div className="modal__top">
              <img
                className="modal__exit"
                src={exit}
                alt={exit}
                onClick={() => {
                  setShowModal();
                }}
              />
              <h1 className="modal__header">Delete {warehouseName} warehouse?</h1>
              <h2 className="modal__confirm">
                Please confirm that you’d like to delete the {warehouseName} from the
                list of warehouses. You won’t be able to undo this action.
              </h2>
            </div>
            <div className="modal__buttons">
              <button
                className="modal__cancel"
                onClick={() => {
                  setShowModal((prev) => !prev);
                }}
              >
                Cancel
              </button>
              <button className="modal__delete" onClick={() => deleteHandler()}>
                Delete
              </button>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};
