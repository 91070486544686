import './InventoryPage.scss';
import sortIcon from '../../assets/images/icons/sort-24px.svg';
import InventoryList from '../../components/InventoryList/InventoryList';
import { React, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

const API_URL = process.env.REACT_APP_API_URL;

const InventoryListPage = () => {
  const [currentInventory, setCurrentInventory] = useState(null);
  const [searchTerm, setSearchTerm] = useState("")

  useEffect(() => {
    const url = `${API_URL}/inventory`;
    axios.get(url)
      .then(response => {
        setCurrentInventory(response.data);
      })
      .catch(error => {
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log('Error', error.message);
        }
      })
  }, []);

  if (!currentInventory) {
    return <h1>Loading...</h1>
  }

  return (
    <section className='inventory'>
      <div className='inventory__top'>
        <h1 className='inventory__title'>Inventory</h1>
        <form className='inventory__form'>
          <input className='inventory__search'
            type={'search'}
            placeholder='Search...'
            onChange={(event) => {
              setSearchTerm(event.target.value)
            }} />
          <Link to={`/inventory/add`}><button style={{ cursor: 'pointer' }} className='inventory__button '>+ Add New Item</button></Link>
        </form>
      </div>
      <div className='inventory__table-row'>
        <div className='inventory__row-wrapper'>
          <div className='inventory__table-head inventory__table-head--pos1'>
            <h3 className='inventory__head-copy'>INVENTORY ITEM</h3>
            <img className='inventory__sort-icon' src={sortIcon} alt="sort" />
          </div>
          <div className='inventory__table-head inventory__table-head--pos2'>
            <h3 className='inventory__head-copy'>CATEGORY</h3>
            <img className='inventory__sort-icon' src={sortIcon} alt="sort" />
          </div>
          <div className='inventory__table-head inventory__table-head--pos3'>
            <h3 className='inventory__head-copy'>STATUS</h3>
            <img className='inventory__sort-icon' src={sortIcon} alt="sort" />
          </div>
          <div className='inventory__table-head inventory__table-head--pos4'>
            <h3 className='inventory__head-copy'>QTY</h3>
            <img className='inventory__sort-icon' src={sortIcon} alt="sort" />
          </div>
          <div className='inventory__table-head inventory__table-head--pos5'>
            <h3 className='inventory__head-copy'>WAREHOUSE</h3>
            <img className='inventory__sort-icon' src={sortIcon} alt="sort" />
          </div>
        </div>
        <div className='inventory__table-head inventory__table-head--pos6'>
          <h3 className='inventory__head-copy'>ACTIONS</h3>
        </div>
      </div>
      {/* Search functionality */}
      {currentInventory.filter((val) => {
        // If empty, return the whole list
        if (searchTerm === "") {
          return val
        } else if (
          // If search matches any fields, return that data
          val.itemName.toLowerCase().includes(searchTerm.toLocaleLowerCase())
          || val.warehouseName.toLowerCase().includes(searchTerm.toLocaleLowerCase())
          || val.category.toLowerCase().includes(searchTerm.toLocaleLowerCase())) {
          return val
        }
        return false;
      }).map((product, i) => {
        return (
          <InventoryList
            i={i}
            product={product}
            key={uuidv4()}
          />)
      })}
    </section>
  )
}

export default InventoryListPage